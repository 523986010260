import { DataModelFieldList } from '@/api/systemData/dataModel'
import { getDataSourceListAll } from '@/api/systemData/dataSource'
export default {
  provide() {
    return {
      getFormInfo: this.getFormInfo
    }
  },
  data() {
    return {}
  },
  created() {
    // this.$store.dispatch('base/getPositionList')
    // this.$store.dispatch('base/getRoleList')
    // this.$store.dispatch('generator/getDepTree')
    // this.$store.dispatch('generator/getGroupTree')
  },
  methods: {
    closeDialog(isRefresh) {
      this.visible = false
      this.$emit('close', isRefresh)
      // this.$store.getters.allTable = []
    },
    prev() {
      this.activeStep -= 1
      if (this.activeStep == 0) this.updateTables()
    },
    stepChick(key) {
      if (this.activeStep <= key) return
      this.activeStep = key
      if (this.activeStep == 0) this.updateTables()
    },
    updateTables() {
      this.mainTable = this.$store.getters.allTable || []
      this.mainTableFields = this.$store.getters.formItemList || []
    },
    onDbChange() {
      this.mainTable = []
    },
    openTableBox() {
      if (!this.dataForm.linkId) {
        this.$message({
          message: '请先选择数据库',
          type: 'error',
          duration: 1000
        })
        return
      }
      this.formVisible = true;
    },
    async closeForm(data) {
      const type = this.dataForm.type
      let queryType = 0
      if (type == 3 || type == 4 || type == 5) queryType = 1
      let checkList = []
      console.log(this.mainTable,'57')
      if (!this.mainTable.length) {
        for (let i = 0; i < data.length; i++) {
          const e = data[i];
          let relationTable = data[0].table
          let typeId = i == 0 ? "1" : "0"
          let res = await DataModelFieldList(this.dataForm.linkId,{
            tableName: e.table_name
          } , queryType)
          let fields = res.data.data
          let item = {
            relationField: "",
            relationTable: i == 0 ? '' : relationTable,
            table: e.table_name,
            tableName: e.table_comment,
            tableField: '',
            typeId,
            fields
          }
          checkList.push(item)
        }
        this.relationTable = checkList[0].table
        this.mainTableFields = checkList[0].fields
        this.mainTable = checkList;
      } else {
        for (let i = 0; i < data.length; i++) {
          const e = data[i];
          let boo = this.mainTable.some(o => o.table == e.table)
          if (!boo) {
            let res = await DataModelFieldList(this.dataForm.linkId, {
              tableName: e.table_name
            }, queryType)
            let fields = res.data.data
            let item = {
              relationField: "",
              relationTable: this.relationTable,
              table: e.table_name,
              tableName: e.table_comment,
              tableField: '',
              typeId: "0",
              fields
            }
            checkList.push(item)
          }
        }
        this.mainTable = [...this.mainTable, ...checkList]
      }
    },
    async updateFields() {
      if (!this.mainTable.length) return this.loading = false
      this.dataForm.linkId = this.dataForm.linkId || '0'
      const type = this.dataForm.type
      let queryType = 0
      if (type == 3 || type == 4 || type == 5) queryType = 1
      for (let i = 0; i < this.mainTable.length; i++) {
        let res = await DataModelFieldList(this.dataForm.linkId, {tableName:this.mainTable[i].table}, queryType)
        let fields = res.data.data
        this.mainTable[i].fields = fields
        if (this.mainTable[i].typeId == '1') {
          this.mainTableFields = this.mainTable[i].fields
          this.relationTable = this.mainTable[i].table
        }
      }
      this.loading = false
    },
    getDbOptions() {
      getDataSourceListAll().then(res => {
        const list = res.data.data || []
        this.dbOptions = list;
        this.dataForm.linkId = res.data.data[0].id;
      })
    },
    getDbType() {
      for (let i = 0; i < this.dbOptions.length; i++) {
        this.dbType = this.dbOptions[i].dbName;
      }
    },
    delItem(row, index) {
      this.$confirm("确定要移除当前行？", this.$t('common.tipTitle'), {
        type: 'warning'
      }).then(() => {
        this.mainTable.splice(index, 1);
        if (row.typeId == '1' && this.mainTable.length) {
          this.mainTable[0].typeId = '1'
          this.mainTable[0].relationTable = ''
          this.mainTableFields = this.mainTable[0].fields
          this.relationTable = this.mainTable[0].table
        }
      }).catch(() => { });
    },
    changeTable(row) {
      this.relationTable = row.table
      this.mainTableFields = row.fields
      for (let i = 0; i < this.mainTable.length; i++) {
        this.$set(this.mainTable[i], "typeId", this.mainTable[i].table === row.table ? '1' : '0')
        this.$set(this.mainTable[i], "relationTable", this.mainTable[i].table === row.table ? '' : this.relationTable)
        this.$set(this.mainTable[i], "relationField", "")
        this.$set(this.mainTable[i], "tableField", "")
      }
    },
    getFormInfo() {
      return this.dataForm
    },
    exist() {
      let isOk = true;
      for (let i = 0; i < this.mainTable.length; i++) {
        const e = this.mainTable[i];
        if (e.typeId == '0') {
          if (!e.tableField) {
            this.$message({
              showClose: true,
              message: `表${e.table}外键字段不能为空`,
              type: 'warning',
              duration: 1000
            });
            isOk = false
            break
          }
        }
        if (e.typeId == '0') {
          if (!e.relationField) {
            this.$message({
              showClose: true,
              message: `表${e.table}关联主键不能为空`,
              type: 'warning',
              duration: 1000
            });
            isOk = false
            break
          }
        }
      }
      return isOk;
    }
  }
}
