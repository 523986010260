<template>
  <el-row>
    <el-form-item label="控件标题">
      <el-input v-model="activeData.__config__.label" placeholder="请输入控件标题" />
    </el-form-item>
    <el-form-item label="占位提示">
      <el-input v-model="activeData.placeholder" placeholder="请输入占位提示" />
    </el-form-item>
    <el-form-item label="默认值">
      <el-cascader :options="activeData.options" clearable
        :show-all-levels="activeData['show-all-levels']" :props="activeData.props.props" filterable
        v-model="activeData.__config__.defaultValue" :key="cascaderKey"></el-cascader>
    </el-form-item>
    <el-form-item label="选项分隔符">
      <el-input v-model="activeData.separator" placeholder="请输入选项分隔符" />
    </el-form-item>
    <template>
      <el-divider>数据选项</el-divider>
      <el-form-item label="" label-width="40px">
        <el-radio-group v-model="activeData.__config__.dataType" size="small"
          style="text-align:center" @change="dataTypeChange">
          <el-radio-button label="static">静态数据</el-radio-button>
          <el-radio-button label="dictionary">数据字典</el-radio-button>
          <el-radio-button label="dynamic">远端数据</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <template v-if="activeData.__config__.dataType === 'static'">
        <!-- 级联选择静态树 -->
        <el-tree draggable id="el-tree" :data="activeData.options" node-key="id" :expand-on-click-node="false"
          :render-content="renderContent" :props="props" />
        <div style="margin-left: 20px">
          <el-button style="padding-bottom: 0" icon="el-icon-circle-plus-outline" type="text"
            @click="addTreeItem">添加父级</el-button>
        </div>
      </template>
      <template v-if="activeData.__config__.dataType === 'dictionary'">
        <el-form-item label="数据字典">
          <el-select v-model="activeData.__config__.dictionaryType" filterable placeholder="请选择数据字典" @change="dictionaryTypeChange">
            <el-option
              v-for="item in dicOptions"
              :key="item.id"
              :label="item.dictValue"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="存储字段">
          <el-select v-model="activeData.props.props.value" placeholder="请选择存储字段">
            <el-option label="id" value="id"></el-option>
            <el-option label="enCode" value="enCode"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-if="activeData.__config__.dataType === 'dynamic'">
        <el-form-item label="远端数据">
          <interface-dialog :value="activeData.__config__.propsUrl"
            :title="activeData.__config__.propsName" popupTitle="远端数据" @change="propsUrlChange" />
        </el-form-item>
        <el-form-item label="存储字段">
          <el-input v-model="activeData.props.props.value" placeholder="请输入存储字段"
            @change="onChange" />
        </el-form-item>
        <el-form-item label="显示字段">
          <el-input v-model="activeData.props.props.label" placeholder="请输入显示字段"
            @change="onChange" />
        </el-form-item>
        <el-form-item label="子级字段">
          <el-input v-model="activeData.props.props.children" placeholder="请输入子级字段"
            @change="onChange" />
        </el-form-item>
        <el-table :data="activeData.__config__.templateJson"
          v-if="activeData.__config__.templateJson && activeData.__config__.templateJson.length">
          <el-table-column type="index" width="50" label="序号" align="center" />
          <el-table-column prop="field" label="参数名称">
            <template slot-scope="scope">
              <span class="required-sign">{{scope.row.required?'*':''}}</span>
              {{scope.row.fieldName?scope.row.field+'('+scope.row.fieldName+')':scope.row.field}}
            </template>
          </el-table-column>
          <el-table-column prop="value" label="表单字段">
            <template slot-scope="scope">
              <el-select v-model="scope.row.relationField" placeholder="请选择表单字段" clearable
                filterable @change="onRelationFieldChange($event,scope.row)">
                <el-option v-for="item in formFieldsOptions" :key="item.realVModel"
                  :label="item.realLabel" :value="item.realVModel">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <el-divider />
    </template>
    <el-form-item label="展示全路径">
      <el-switch v-model="activeData['show-all-levels']" />
    </el-form-item>
    <el-form-item label="能否清空">
      <el-switch v-model="activeData.clearable" />
    </el-form-item>
    <el-form-item label="能否搜索">
      <el-switch v-model="activeData.filterable" />
    </el-form-item>
    <el-form-item label="能否多选">
      <el-switch v-model="activeData.props.props.multiple" @change="onChange" />
    </el-form-item>
    <el-form-item label="是否禁用">
      <el-switch v-model="activeData.disabled" />
    </el-form-item>
    <el-form-item label="是否必填">
      <el-switch v-model="activeData.__config__.required" />
    </el-form-item>
    <!-- <el-form-item label="显示标签">
      <el-switch v-model="activeData.__config__.showLabel" />
    </el-form-item> -->
    <treeNode-dialog :visible.sync="dialogVisible" title="添加选项" @commit="addNode" />
  </el-row>
</template>
<script>
import comMixin from '../mixin';
import dynamicMixin from '../dynamicMixin';
import TreeNodeDialog from './TreeNodeDialog'
// import { getDictionaryDataSelector } from '@/api/systemData/dictionary'
import { getDataInterfaceRes } from '@/api/systemData/dataInterface'
import {getChildList} from "@/api/system/dictbiz";
export default {
  mixins: [comMixin, dynamicMixin],
  components: { TreeNodeDialog },
  data() {
    return {
      dialogVisible: false,
      currentNode: {},
      props: {
        value: 'id',
        label: 'fullName',
        children: 'children',
      },
      cascaderKey: +new Date()
    }
  },
  mounted() {
    console.log(this.activeData,'135')
  },
  methods: {
    renderContent(h, { node, data, store }) {
      return (
        <div class="custom-tree-node">
          <span>{node.label}</span>
          <span class="node-operation">
            <i on-click={() => this.append(data)}
              class="el-icon-plus"
              title="添加"
            ></i>
            <i on-click={() => this.remove(node, data)}
              class="el-icon-delete"
              title="删除"
            ></i>
          </span>
        </div>
      )
    },
    addTreeItem() {
      this.dialogVisible = true
      this.currentNode = this.activeData.options
    },
    addNode(data) {
      this.currentNode.push(data)
    },
    append(data) {
      if (!data.children) {
        this.$set(data, 'children', [])
      }
      this.dialogVisible = true
      this.currentNode = data.children
    },
    remove(node, data) {
      this.activeData.__config__.defaultValue = [] // 避免删除时报错
      const { parent } = node
      const children = parent.data.children || parent.data
      const index = children.findIndex(d => d.id === data.id)
      children.splice(index, 1)
    },
    onChange() {
      this.cascaderKey = +new Date()
      this.activeData.__config__.renderKey = +new Date()
      this.activeData.__config__.defaultValue = []
    },
    dataTypeChange() {
      console.log(this.activeData,'182')
      this.cascaderKey = +new Date()
      this.activeData.__config__.renderKey = +new Date()
      this.activeData.__config__.defaultValue = []
      // this.activeData.options = []
      this.activeData.props.props.value = 'id'
      this.activeData.props.props.label = 'fullName'
      this.activeData.props.props.children = 'children'
      this.activeData.__config__.dictionaryType = ''
      this.activeData.__config__.propsUrl = ''
      this.activeData.__config__.propsName = ''
      this.activeData.__config__.templateJson = []
    },
    dictionaryTypeChange(val) {
      this.activeData.__config__.defaultValue = []
      if (!val) {
        this.activeData.options = []
        return
      }
      getChildList(1,500,val).then(res => {
        let data = res.data.data;
        data.forEach((item) => {
          item.fullName = item.dictValue;
          item.enCode = item.dictKey;
        })
        this.activeData.options = data
      })
    },
    propsUrlChange(val, row) {
      this.activeData.__config__.defaultValue = []
      if (!val) {
        this.activeData.__config__.propsUrl = ''
        this.activeData.__config__.propsName = ''
        this.activeData.__config__.templateJson = []
        this.activeData.options = []
        return
      }
      let list = row.requestParameters ? JSON.parse(row.requestParameters) : []
      this.activeData.__config__.propsUrl = val
      this.activeData.__config__.propsName = row.fullName
      this.activeData.__config__.templateJson = list.map(o => ({ ...o, relationField: '' }))
      let query = {
        paramList: this.activeData.__config__.templateJson || [],
      }
      getDataInterfaceRes(val, query).then(res => {
        let data = res.data
        if (Array.isArray(data)) {
          this.activeData.options = data
        } else {
          this.activeData.options = []
        }
      }).catch(() => {
        this.activeData.__config__.propsUrl = ''
        this.activeData.__config__.propsName = ''
        this.activeData.__config__.templateJson = []
        this.activeData.options = []
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .custom-tree-node {
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  .node-operation {
    float: right;
  }
  i[class*='el-icon'] + i[class*='el-icon'] {
    margin-left: 6px;
  }
  .el-icon-plus {
    color: #409eff;
  }
  .el-icon-delete {
    color: #157a0c;
  }
}
</style>
