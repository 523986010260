<template>
  <el-row>
    <el-form-item label="控件标题">
      <el-input v-model="activeData.__config__.label" placeholder="请输入控件标题" />
    </el-form-item>
    <el-form-item label="占位提示">
      <el-input v-model="activeData.placeholder" placeholder="请输入占位提示" />
    </el-form-item>
    <el-form-item label="格式">
      <el-radio-group v-model="activeData.level" @change="levelChange" class="level">
        <el-radio :label="0">省</el-radio>
        <el-radio :label="1">省市</el-radio>
        <el-radio :label="2">省市区</el-radio>
        <el-radio :label="3">省市区-街道</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="能否清空">
      <el-switch v-model="activeData.clearable" />
    </el-form-item>
    <el-form-item label="能否多选">
      <el-switch v-model="activeData.multiple" @change="levelChange" />
    </el-form-item>
    <el-form-item label="是否禁用">
      <el-switch v-model="activeData.disabled" />
    </el-form-item>
    <el-form-item label="是否必填">
      <el-switch v-model="activeData.__config__.required" />
    </el-form-item>
  </el-row>
</template>
<script>
import comMixin from './mixin';
export default {
  props: ['activeData'],
  mixins: [comMixin],
  data() {
    return {}
  },
  created() { },
  methods: {
    levelChange() {
      this.$set(this.activeData.__config__, 'defaultValue', [])
      this.activeData.__config__.renderKey = +new Date() // 更新renderKey,重新渲染该组件
    }
  }
}
</script>
<style lang="scss">
.level {
  .el-radio {
    display: block;
    margin: 10px 0;
  }
}
</style>